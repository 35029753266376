#notification {
  &Center {
    background-color: rgba(0,0,0,0);
    padding: 52px 0 50px 0;
    overflow: hidden;
    position: fixed;
    z-index: 99999;
    display: none;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    &.open {
      display: block;

      #notification-panel {
        animation-name: notiSlideOpen;
        animation-duration: .3s;
      }
    }

    #notification-0 {
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      border: 2px solid #ccc;
      margin: 0 10px 10px 10px;
      padding: 0.01em 16px;
      text-align: center;
      font-size: 18px;
      opacity: 0.60;

      &:hover {
        opacity: 1;
        -webkit-backface-visibility: visible;
                backface-visibility: visible;
      }
    }
  }

  &-panel {
    position: absolute;
    max-width: 400px;
    z-index: 100000;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: #fff;
    border-left: 1px solid #ccc;
  }

  &-title {
    padding: 6.5px 7px 6.5px 10px;
    text-align: center;
    font: {
      weight: 900;
      size: 20px;
    };

    button {
      border: 1px solid #ccc;
      background: #ededed;
      border-radius: 4px;
      padding: 3px 5px;
      margin-left: 5px;
      color: #111;
      float: right;
      font: {
        weight: 100;
        size: 17px;
      };

      &:hover {
        background: #ccc;
        box-shadow: none;
        color: #d1271a;
      }
    }
  }

  &-list {
    overflow-x: hidden;
    border: 2px solid #efefef;
    height: calc(100% - 40px);
    -webkit-box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 4px 8px;
    margin: 0;

    &:empty {
      @extend .empty-bg;
    }

    &::-webkit-scrollbar-thumb {
      background: #999 !important
    }

    .jdb-toast {
      border-radius: 7px;
      border: 4px solid rgba(204, 204, 204, 0.43);
      -webkit-box-shadow: none !important;
              box-shadow: none !important;
    }

    .jdb-toast-time {
      background: linear-gradient(to left, transparent calc(100% - 70px), rgba(25, 8, 5, 0.1));
      border-radius: 2px;
      padding: 2px 4px;
      margin-top: 4px;
      font-size: 12px;
      width: 100%;
      float: left;
    }

    .jdb-toast-target .jdb-toast-capsule {
      &::before, &::after {
        display: none;
      }
    }

    .jdb-toast-animateInside {
      animation: none !important;
      opacity: 1 !important;
    }
  }
}

@keyframes notiSlideOpen {
  from { opacity: 0; right: -400px }
  to   { opacity: 1; right: 0px }
}
