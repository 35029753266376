.gh-comment {
  &s-container {
    &-toggle {
      max-height: calc(100vh - 125px);
      overflow-y: auto;
    }
  }

  &-item {
    &:not(:first-child)::before {
      content: "";
      position: relative;
      top: 0;
      bottom: -16px;
      margin-top: -18px;
      left: 25px;
      width: 2px;
      height: 18px;
      display: block;
      background-color: #ccc;
    }

    &.deleting {
      padding: 4px 8px;

      &:not(:first-child)::before {
        top: -4px;
      }
    }
  }

  &s-toggler {
    position: relative;
    top: -4px
  }

  &-link {
    text-decoration: none !important;

    &:hover {
      color: var(--color-text-link);
    }
  }

  &-header {
    .jdb-dropdown-click {
      font-size: 15px;

      .jdb-dropdown-content {
        right: 0px;
        margin-top: 8px;
        max-width: 200px;
        display: none;
        z-index: 5;

        hr {
          margin: 5px 0;
        }
      }
    }

    a[href]::after {
      display: none;
    }

    .gh-comment-avatar {
      width: 20px !important;
      height: 20px !important;
      margin-right:5px;
    }

    .gh-comment-author {
      font-weight: bold;
      text-decoration: none !important;

      &::after {
        display: none;
      }
    }
  }

  &-body {
    font: {
      family: -apple-system, BlinkMacSystemFont, "Segoe UI",
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      size: 15px;
    };
    word-break: break-word;

    .username {
      font-weight: 500;
    }

    a[href] {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:not(.username) {
        color: var(--color-text-link);
      }
    }

    blockquote {
      margin: 0;
      padding: 0 1em;
      color: #555555;
      border-left: .25em solid #444;
    }

    pre {
      width: auto;
      overflow-x: auto;
      padding: 8px 16px;
      border-radius: 8px;
      color: #000;
      background-color: rgba(187, 187, 187, 0.18);
      font-size: 14px;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        &-thumb {
          background-color: #ddd !important;
        }
      }
    }
  }
}
