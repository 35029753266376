#consoleModal {
  .CodeMirror-scroll { height: 150px }
  .console-editor {
    font-size: 14px;
    position: relative;
    margin-bottom: 1em;
    border: 1px solid #3f87a6;
    border-left: 5px solid #3f87a6
  }
  .output-console {
    background-color:#eee;
    border:1px solid#ddd;
    font-family:monospace;
    display: inline-block;
    position: relative;
    max-height: 200px;
    overflow-y:auto;
    font-size: 14px;
    padding: .8em;
    width: 91%;
    margin: 0;

    code {
      width: 100%;
      height: 100px;
      white-space: pre;
      vertical-align: middle;
      word-break: break-word;

      &::-webkit-scrollbar-thumb {
        background-color: #e0e0e0 !important;
        border-radius: 2px;
      }
    }
  }
}

@supports ((display: -webkit-box) or (display: flex)) {
  #consoleModal {
    .buttons-container {
      -ms-flex-item-align: start;
          align-self: flex-start;
      width: auto
    }

    .output-console-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
         -ms-flex-align: center;
            align-items: center;
    }

    .run { margin-right: 10px }
    .output-console {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 10;
          -ms-flex: 10 150px;
              flex: 10 150px;
      -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    }
  }
}

#notesModal {
  ul#notes-list {
    li {
      margin: 16px 0;
      border-radius: 4px;
      border-bottom: none;
      -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,0.1), 0 0 2px 0 rgba(0,0,0,0.1);
         -moz-box-shadow: 0 0 2px 0 rgba(0,0,0,0.1), 0 0 2px 0 rgba(0,0,0,0.1);
              box-shadow: 0 0 2px 0 rgba(0,0,0,0.1), 0 0 2px 0 rgba(0,0,0,0.1);

      &:only-child i.fa.fa-arrows { display: none }

      details {
        &[open] .quick-nav {
          i.fa.fa-pencil,
          i.fa.fa-trash {
            display: none
          }
        }

        & > summary {
          cursor: pointer;

          &:focus { outline: none }
        }
      }

      &:hover .quick-nav * {
        display: inline-block
      }

      &.checked-note {
        background-color: #2196F3;

        &:hover {
          background-color: rgb(18, 124, 211)
        }

        .quick-nav i.fa.fa-check-square {
          display: inline-block
        }

        details { color: #ffffff }
      }

      &.is-important {
        border-left: 4px solid #ccc;

        .important-btn {
          display: inline-block;
          color: #ffeb3b;
        }
      }

      .quick-nav {
        i.fa.fa-arrows { cursor: move }
        i.fa:not(:first-child) { margin-left: 10px }

        & * { display: none }
      }
    }

    &.only-important {
      & > li:not(.is-important) {
        height: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
      }
    }
  }
}

#loadFileModal {
  .gi-re-blank {
    float: right;
    display: none;
    cursor: pointer;
    font-size: 15px;
    line-height: 1.2;
    padding: 0px 4px;
    margin-top: 2px;
  }

  #gi-re-open-table tr:hover {
    .gi-re-blank {
      display: inline-block;
    }
  }
}
