/*! JdBEditor */
.jdb-theme {
  color: #fff !important;
  background-color: #557baa !important;

  &-l5 {color:#000 !important; background-color:#f5f7fa !important}
  &-l4 {color:#000 !important; background-color:#dde5ee !important}
  &-l3 {color:#000 !important; background-color:#bbcadd !important}
  &-l2 {color:#000 !important; background-color:#99b0cc !important}
  &-l1 {color:#fff !important; background-color:#7796bb !important}
  &-d1 {color:#fff !important; background-color:#4d6f99 !important}
  &-d2 {color:#fff !important; background-color:#446388 !important}
  &-d3 {color:#fff !important; background-color:#3c5677 !important}
  &-d4 {color:#fff !important; background-color:#334a66 !important}
  &-d5 {color:#fff !important; background-color:#2b3e55 !important}

  &-light  {color:#000 !important; background-color:#f5f7fa !important}
  &-dark   {color:#fff !important; background-color:#2b3e55 !important}
  &-action {color:#fff !important; background-color:#2b3e55 !important}
}

.jdb-hover {
  &-theme:hover {color:#fff !important; background-color:#557baa !important}
  &-text-theme:hover {color:#557baa !important}
  &-border-theme:hover {border-color:#557baa !important}
}

.jdb-text-theme   {color:#557baa !important}
.jdb-border-theme {border-color:#557baa !important}

.jdb-theme-l2 a, .jdb-theme-l1 a, .jdb-theme-d1 a, .jdb-theme-d2 a, .jdb-theme-d3 a,
.jdb-theme-d4 a, .jdb-theme-d5 a, .jdb-theme-dark a, .jdb-theme-action a, .jdb-theme a {
  -webkit-text-decorations-in-effect: underline !important;
  color: #fff !important;
}