.tooltip.light-theme {
  color: #26323d;
  box-shadow: 0 0 20px 4px rgba(154,161,177,.15),
    0 4px 80px -8px rgba(36,40,47,.25),
    0 4px 4px -2px rgba(91,94,105,.15);
  background-color: #fafafa;
  border-width: 0;

  &[data-animatefill] { background-color: transparent }
  .tooltip-backdrop { background-color: #fafafa }
  .tooltip-roundarrow { fill: #fafafa }
}

.tooltip-popper {
  &[x-placement^=top] .tooltip {
    &.translucent-theme .tooltip-arrow {
      border-top: 7px solid rgba(0,0,0,.7);
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
    }

    &.google-theme .tooltip-arrow {
      border-top: 8px solid #505355;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }

    &.light-border-theme {
      .tooltip-roundarrow::after {
        top: 1px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      .tooltip-arrow {
        border-top-color:#fff;

        &::after { border-top-color: rgba(0,8,16,.24) }
        &::before { border-top-color: #fff }
        &::after, &::before{
          border-top:8px solid;
          border-left:8px solid transparent;
          border-right:8px solid transparent;
          left:-8px;
          top:-7px;
        }
      }
    }
  }

  &[x-placement^=bottom] .tooltip {
    &.translucent-theme .tooltip-arrow {
      border-bottom: 7px solid rgba(0,0,0,.7);
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
    }

    &.light-border-theme {
      .tooltip-roundarrow:after { top: -1px }
      .tooltip-arrow {
        border-bottom-color: #fff;

        &::after, &::before { border-bottom: 8px solid; bottom: -7px }
        &::after { border-bottom-color: rgba(0,8,16,.24) }
        &::before { border-bottom-color: #fff }
      }
    }
  }

  &[x-placement^=left] .tooltip.translucent-theme .tooltip-arrow{border-left:7px solid rgba(0,0,0,.7);border-top:7px solid transparent;border-bottom:7px solid transparent}
  &[x-placement^=right] .tooltip.translucent-theme .tooltip-arrow{border-right:7px solid rgba(0,0,0,.7);border-top:7px solid transparent;border-bottom:7px solid transparent}
}


.tooltip-popper .tooltip.translucent-theme,
.tooltip-popper .tooltip.translucent-theme .tooltip-backdrop{background-color:rgba(0,0,0,.7)}
.tooltip-popper .tooltip.translucent-theme .tooltip-roundarrow{fill:rgba(0,0,0,.7)}
.tooltip-popper .tooltip.translucent-theme[data-animatefill]{background-color:transparent}
.tooltip-popper .tooltip.rounded-theme{border-radius:10px}
.tooltip-popper .tooltip.gradient-theme{font-weight:700}
.tooltip-popper .tooltip.gradient-theme{background:radial-gradient(circle at 67% 60%,#95d8ff,#557baa,#9c24ff,#cd2121)}

.tooltip-popper[x-placement^=bottom] .tooltip.google-theme .tooltip-arrow{border-bottom:8px solid #505355;border-right:8px solid transparent;border-left:8px solid transparent}
.tooltip-popper[x-placement^=left] .tooltip.google-theme .tooltip-arrow{border-left:8px solid #505355;border-top:8px solid transparent;border-bottom:8px solid transparent}
.tooltip-popper[x-placement^=right] .tooltip.google-theme .tooltip-arrow{border-right:8px solid #505355;border-top:8px solid transparent;border-bottom:8px solid transparent}

.tooltip.google-theme{background-color:#505355;padding:.25rem .4rem;font-size:.85rem;font-weight:600}
.tooltip.google-theme .tooltip-backdrop{background-color:#505355}
.tooltip.google-theme .tooltip-roundarrow{fill:#505355}
.tooltip.google-theme[data-animatefill]{background-color:transparent}

.tooltip.light-border-theme{background-color:#fff;background-clip:padding-box;border:1px solid rgba(0,8,16,.15);color:#26323d;box-shadow:0 3px 14px -.5px rgba(0,8,16,.08)}
.tooltip.light-border-theme .tooltip-backdrop{background-color:#fff}
.tooltip.light-border-theme .tooltip-arrow::after,
.tooltip.light-border-theme .tooltip-arrow::before,
.tooltip.light-border-theme .tooltip-roundarrow::after,
.tooltip.light-border-theme .tooltip-roundarrow::before{content:"";position:absolute;z-index:-1}
.tooltip.light-border-theme .tooltip-roundarrow{fill:#fff}
.tooltip.light-border-theme .tooltip-roundarrow::after{background-image:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyNCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPjxwYXRoIGQ9Ik0zLDhjMCwwIDIuMDIxLC0wLjAxNSA1LjI1MywtNC4yMThjMS4zMzEsLTEuNzMxIDIuNTQ0LC0yLjc3NSAzLjc0NywtMi43ODJjMS4yMDMsLTAuMDA3IDIuNDE2LDEuMDM1IDMuNzYxLDIuNzgyYzMuMjUxLDQuMjIzIDUuMjM5LDQuMjE4IDUuMjM5LDQuMjE4bC0xOCwwWiIgc3R5bGU9ImZpbGw6IzAwMDgxNjtmaWxsLW9wYWNpdHk6MC4yMDM5MjI7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9zdmc+);background-size:24px 8px;width:24px;height:8px;left:0;top:0;fill:rgba(0,8,16,.15)}



.tooltip-popper[x-placement^=left] .tooltip.light-border-theme .tooltip-roundarrow:after{left:1px;top:0;-webkit-transform:rotate(90deg);transform:rotate(90deg)}
.tooltip-popper[x-placement^=left] .tooltip.light-border-theme .tooltip-arrow{border-left-color:#fff}
.tooltip-popper[x-placement^=left] .tooltip.light-border-theme .tooltip-arrow::after,
.tooltip-popper[x-placement^=left] .tooltip.light-border-theme .tooltip-arrow:before{border-left:8px solid;left:-7px}
.tooltip-popper[x-placement^=left] .tooltip.light-border-theme .tooltip-arrow::after{border-left-color:rgba(0,8,16,.24)}
.tooltip-popper[x-placement^=left] .tooltip.light-border-theme .tooltip-arrow::before{border-left-color:#fff}
.tooltip-popper[x-placement^=right] .tooltip.light-border-theme .tooltip-roundarrow::after{left:-1px;top:0;-webkit-transform:rotate(-90deg);transform:rotate(-90deg)}
.tooltip-popper[x-placement^=right] .tooltip.light-border-theme .tooltip-arrow{border-right-color:#fff}
.tooltip-popper[x-placement^=right] .tooltip.light-border-theme .tooltip-arrow::after,
.tooltip-popper[x-placement^=right] .tooltip.light-border-theme .tooltip-arrow::before{border-right:8px solid;right:-7px}
.tooltip-popper[x-placement^=right] .tooltip.light-border-theme .tooltip-arrow::after{border-right-color:rgba(0,8,16,.24)}
.tooltip-popper[x-placement^=right] .tooltip.light-border-theme .tooltip-arrow::before{border-right-color:#fff}
.tooltip-popper[x-placement^=bottom] .tooltip.light-border-theme .tooltip-arrow::after,
.tooltip-popper[x-placement^=bottom] .tooltip.light-border-theme .tooltip-arrow::before,

.tooltip-popper[x-placement^=left] .tooltip.light-border-theme .tooltip-arrow::after,
.tooltip-popper[x-placement^=left] .tooltip.light-border-theme .tooltip-arrow::before,
.tooltip-popper[x-placement^=right] .tooltip.light-border-theme .tooltip-arrow:after,
.tooltip-popper[x-placement^=right] .tooltip.light-border-theme .tooltip-arrow::before{top:-8px;border-top:8px solid transparent;border-bottom:8px solid transparent}