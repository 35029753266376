/*! JdBEditor */
#color-picker-md {
  #color {
    height: 42px !important;
    padding-left: 4px !important;
  }

  .valide-color {
    margin-bottom: 4px !important;
    padding: 10px 16px !important;
  }

  #cp-canvas {
    background-color: rgba(43, 62, 85, 0.02);
    border: 1px solid #2b3e55;
    box-sizing: border-box;
    cursor: crosshair;
    margin: 10px auto;
    height: 360px;
  }

  #cp-readout {
    background-color: rgba(117, 117, 117, 0.2);
    border: 1px #333 solid;
    box-sizing: border-box;
    margin: 10px auto;
  }

  #cp-swatch {
    border: 1px solid #000;
    margin: 10px auto;
  }

  .cp-theme, .cp-swatchValue {
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)!important;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)!important;
    background-color: rgb(43, 62, 85);
    padding: 3px 10px;
    color: #fff;
  }

  .color-picker-selected-item {
    border: 1px solid #ccc;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    &:hover {
      box-shadow: 2px 3px 3px 0 #ccc;
      opacity: 0.8;
    }
  }

  #cp-selected-colors {
    padding: 10px;

    &[data-colors="true"] {
      display: grid;
      grid: {
        template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        template-rows: 1fr 1fr;
        column-gap: 10px;
        row-gap: 10px;
      };

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

.color-picker-selected-item {
  &:nth-child(1)  { grid-area: 1 / 1 / 2 / 2 }
  &:nth-child(2)  { grid-area: 1 / 2 / 2 / 3 }
  &:nth-child(3)  { grid-area: 1 / 3 / 2 / 4 }
  &:nth-child(4)  { grid-area: 1 / 4 / 2 / 5 }
  &:nth-child(5)  { grid-area: 1 / 5 / 2 / 6 }
  &:nth-child(6)  { grid-area: 1 / 6 / 2 / 7 }
  &:nth-child(7)  { grid-area: 1 / 7 / 2 / 8 }
  &:nth-child(8)  { grid-area: 1 / 8 / 2 / 9 }
  &:nth-child(9)  { grid-area: 1 / 9 / 2 / 10}
  &:nth-child(10) { grid-area: 1 / 10/ 2 / 11}
  &:nth-child(11) { grid-area: 2 / 1 / 3 / 2 }
  &:nth-child(12) { grid-area: 2 / 2 / 3 / 3 }
  &:nth-child(13) { grid-area: 2 / 3 / 3 / 4 }
  &:nth-child(14) { grid-area: 2 / 4 / 3 / 5 }
  &:nth-child(15) { grid-area: 2 / 5 / 3 / 6 }
  &:nth-child(16) { grid-area: 2 / 6 / 3 / 7 }
  &:nth-child(17) { grid-area: 2 / 7 / 3 / 8 }
  &:nth-child(18) { grid-area: 2 / 8 / 3 / 9 }
  &:nth-child(19) { grid-area: 2 / 9 / 3 / 10}
  &:nth-child(20) { grid-area: 2 / 10/ 3 / 11}
}